<template>
    <div style="margin-bottom: 140px;">
        <div class="otherNews" v-for="(item, index) in otherInfo" :key="index">
            <div class="img"><img :src="
                        require('../../assets/images/logo/' +
                            item.img +
                            '.png')
                    " alt="" /></div>
            <div class="right">
                <div class="title">{{ item.title }}</div>
                <div class="content">{{ item.content }}</div>
                <div class="date">{{ item.date }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'moreArticle',
    data() {
        return {}
    },
    props: {
        otherInfo: {
            type: Array
        }
    },
    watch: {
        otherInfo(e) {}
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.otherNews {
    width: 850px;
    height: 225px;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    .img {
        width: 300px;
        height: 225px;
        img {
            width: 300px;
            height: 225px;
        }
    }
    .right {
        display: flex;
        align-items: space-around;
        flex-direction: column;
        .title {
            width: 516px;
            height: 68px;
            font-size: 24px;
            font-family: HarmonyOS Sans SC;
            font-weight: bold;
            line-height: 36px;
            color: #00227d;
            margin-top: 15px;
            margin-bottom: 30px;
        }
        .content {
            width: 516px;
            height: 47px;
            font-size: 14px;
            font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
            font-weight: 400;
            line-height: 28px;
            color: #111a37;
            margin-bottom: 30px;
        }
        .date {
            width: 88px;
            height: 21px;
            font-size: 16px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            line-height: 36px;
            color: #a9b3cc;
            opacity: 1;
        }
    }
}
</style>
