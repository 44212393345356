<template>
    <div class="mediaCenterDetail">
        <div class="bread">
            <a-breadcrumb separator=">">
                <a-breadcrumb-item>
                    <router-link to="/">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link to="/mediaCenter">媒体中心</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    酒星动态
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="absolute-share">
            <p>分享</p>
            <img src="../assets/images/logo/wechat@2x.png" alt="">
            <img src="../assets/images/logo/link@2x.png" alt="">
        </div>
        <div class="center">
            <div class="maintitle">
                <div class="title">
                    白酒产业链综合服务商酒星科技重磅亮相，新锐耀春糖
                </div>
                <div class="date">2021.04.04</div>
            </div>
            <div class="maincontent">
                <div class="contentstyle">
                    2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业“晴雨表”之称的行业盛会，吸引来数千家企业、数万种商品参展。
                </div>
                <div><img src="../assets/images/logo/蒙版组3010@2x.png" alt="" /></div>
                <div class="contentstyle">
                    4月4日—6日则是各大酒企、品牌春糖会期间参与酒店展最火爆的“黄金档期”。致力成为国内领先的白酒产业链综合服务商的四川酒星科技有限公司也在酒店展期间重磅亮相。该企业从销售赋能、品牌赋能、产品赋能、金融赋能、技术赋能着手，覆盖中小酒企、经销商、零售终端、消费者，专注为全省乃至全国的白酒供应链上下游服务，助力白酒产业发展进入新的时代，期望能够不断影响中国白酒产业品牌与渠道变革，为中国白酒产业的健康持续发展添砖加瓦。
                </div>
                <div><img src="../assets/images/logo/jytjjukuy@2x.png" alt=""></div>
                <div class="contentstyle">
                    美赢集团董事兼总裁、酒星科技首席执行官张鹏先生，在本次糖酒会媒体采访中首次对公众阐明了酒星科技的成立背景和企业使命。四川酒星科技有限公司是一家服务于酒类供应链上下游企业的综合服务商，隶属于四川美赢集团有限公司。该企业的创始初心是“卖·好酒，卖好·酒”。公司业务雏形始于2016年，创始团队均为白酒行业的生力军，属于白酒流通领域的一股新势力。
                </div>
                <div class="contentstyle">
                    经过多年的资源累积优势和战略布局，公司酒星科技将以2B、2C相结合的业务模式，深度融合线上线下交易与消费场景，利用专业生产技术、品牌运营、市场营销、互联网技术、产业金融等手段，创新性的为酒厂、酒商、白酒零售终端深度赋能，提供白酒产业链一站式综合服务，实现优质酒类资源的共享、互利、共赢，为B端客户创造更多价值，为C端消费者带来更多平价优质产品。
                </div>
            </div>
            <div class="wineProduct">
                <div class="wineTitle">相关阅读</div>
                <div style="margin:20px 0 0 0">
                    <img
                        src="../assets/images/business/point.png"
                        style="width:40px;height:20px"
                        alt=""
                    />
                </div>
            </div>
            <div>
                <MoreArticle :otherInfo="otherInfo"></MoreArticle>
            </div>
        </div>
    </div>
</template>

<script>
import MoreArticle from '../components/moreArticle/moreArticle.vue'
export default {
    name: 'mediaCenterDetail',
    components:{
        MoreArticle
    },
    data(){
        return{
            otherInfo:[
                {
                    img:'test1',
                    title:'白酒产业链综合服务商酒星科技重磅亮相，新锐耀春糖',
                    content:'2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业……',
                    date:'2021.04.04'
                },
                {
                    img:'test2',
                    title:'白酒产业链综合服务商酒星科技',
                    content:'2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业……',
                    date:'2021.04.04'
                },
                {
                    img:'test3',
                    title:'白酒产业链综合服务商酒星科技重磅亮相，新锐耀春糖',
                    content:'2021年4月7日—9日，第104届全国春季糖酒商品交易会将在天府之国成都西博会隆重开幕。有着中国食品行业……',
                    date:'2021.04.04'
                },
            ]
        }
    },
    methods:{

    }
}
</script>

<style lang="scss" scoped>
.mediaCenterDetail{
    margin-top: 100px;
    width: 100%;
    position: relative;
}
.absolute-share{
    position: absolute;
    width: 81px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 355px;
    right: 180px;
    p{
        font-size: 16px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        line-height: 32px;
        color: #616266;
    }
    img{
        cursor: pointer;
        margin: 10px;
        width: 30px;
        height: 30px;
    }
}
.bread{
    height: 68px;
    line-height: 68px;
    background: #F7F9FC;
    padding-left: 300px;
    .ant-breadcrumb {
        height: 68px;
        line-height: 68px;
    }
}
// 内容居中显示
.center {
    width: 844px;
    margin: 0 auto;
}
.maintitle {
    // margin-top: 100px;
    padding-top: 60px;
    .title {
        width: 844px;
        height: 118px;
        font-size: 42px;
        font-family: HarmonyOS Sans SC;
        font-weight: bold;
        line-height: 63px;
        color: #00227d;
    }
    .date {
        width: 100%;
        font-size: 16px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        line-height: 61px;
        color: #a9b3cc;
        border-bottom: 1px solid #f0f2f5;
    }
}
.maincontent {
    .contentstyle {
        width: 844px;
        // height: 54px;
        font-size: 16px;
        font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
        font-weight: 400;
        line-height: 32px;
        color: #616266;
        opacity: 1;
        margin: 40px 0;
    }
    img {
        width: 100%;
        height: 372px;
    }
}
.wineProduct {
    width: 147px;
    margin-top: 100px;
    .wineTitle {
        width: 147px;
height: 50px;
font-size: 36px;
font-family: Alibaba PuHuiTi;
font-weight: bold;
line-height: 59px;
color: #00227D;
opacity: 1;
    }
    .brand {
        width: 321px;
        height: 93px;
        font-size: 26px;
        font-family: HarmonyOS Sans SC;
        font-weight: 900;
        line-height: 59px;
        color: #2b4079;
        opacity: 0.2;
    }
}
</style>
